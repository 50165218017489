import React from 'react';

import { Button } from '../button/Button';
import { FormField } from '../form/FormField';
import clsx from 'clsx';
import { useCustomerService } from '@sus-core/state/xstate/customer/useCustomerService';
import { FormProvider, useForm } from 'react-hook-form';
import { PAGE_CONTAINER_CSS } from '@sus-core/utils/cssClasses';
import { Icons } from '../icon/Icons';
import { PasswortRequirementsHint } from './PasswortRequirementsHint';
import { emailValidation, requiredValidation } from '../form/validationRules';
import { useI18n } from '@sus-core/i18n/useI18n';

export function RegisterForm({
  className,
  onSuccess,
}: {
  className?: string;
  onSuccess?: () => void;
}) {
  const { t } = useI18n();
  const { createCustomer, error, loading } = useCustomerService();

  const methods = useForm({
    mode: 'all',
  });

  const { handleSubmit, formState, getValues, trigger } = methods;

  const onSubmit = ({
    firstname,
    lastname,
    email,
    taxvat,
    password,
  }: {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    taxvat: string;
  }) => {
    createCustomer({ firstname, lastname, email, taxvat, password })
      .then(() => onSuccess?.())
      .catch(e => console.error(e));
  };

  const { errors } = formState;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={clsx(
          'flex flex-row flex-wrap gap-8',
          PAGE_CONTAINER_CSS,
          className
        )}>
        <div className="w-full md:flex-1 b-box space-y-4">
          <h3>Persönliche Informationen</h3>
          <FormField
            name="firstname"
            label="Vorname"
            required={requiredValidation}
            className={clsx(
              errors && errors['firstname'] && 'border border-error'
            )}>
            <input type="text" required />
          </FormField>
          <FormField
            name="lastname"
            label="Nachname"
            required={requiredValidation}
            className={clsx(
              errors && errors['lastname'] && 'border border-error'
            )}>
            <input type="text" required />
          </FormField>
          <FormField
            name="email"
            label="E-Mail"
            required={requiredValidation}
            pattern={emailValidation}
            className={clsx(
              errors && errors['email'] && 'border border-error'
            )}>
            <input type="email" required />
          </FormField>
          <FormField name="taxvat" label="USt.-ID">
            <input type="text" />
          </FormField>
        </div>

        <div className="w-full md:flex-1 b-box space-y-4">
          <h3>Anmeldedaten</h3>
          <FormField
            label="Passwort"
            name="password"
            required={requiredValidation}
            minLength={8}
            error={
              errors['password'] &&
              `Pflichtfeld: Mind. 8 Zeichen Länge, Gross-/Kleinschreibung, mind. eine Zahl sowie mind. ein Sonderzeichen`
            }>
            <input
              autoComplete="off"
              type="password"
              onChange={() => trigger('password-repeat')}
            />
          </FormField>
          <FormField
            name="password-repeat"
            error={
              errors['password-repeat'] &&
              'Die Passwörter müssen übereinstimmen.'
            }
            label="Passwort bestätigen"
            validate={value => {
              return value && value === getValues()['password'];
            }}
            required={requiredValidation}>
            <input
              autoComplete="off"
              type="password"
              // ref={register({
              //   required: true,
              //   validate: value => {
              //     return value && value === getValues()['password'];
              //   },
              // })}
              required
            />
          </FormField>

          <PasswortRequirementsHint />
        </div>

        {error && (
          <div className="w-full flex justify-center items-center p-4 rounded-lg bg-white border-default border-danger text-danger">
            <div className="rounded-full">
              <Icons.Danger className="text-white-1 mr-4 filter hue-rotate-180" />
            </div>
            {t(error?.message)}
          </div>
        )}

        <Button
          variant="primary"
          size={1}
          disabled={!formState.isValid}
          loading={loading}
          className="w-full self-center"
          type="submit">
          Absenden
        </Button>
      </form>
    </FormProvider>
  );
}
