import React, { useState } from 'react';
import Layout from '@sus-core/components/scaffolding/Layout';

import { RegisterForm } from '@sus-core/components/user/RegisterForm';
import { PageProvider } from '@sus-core/hooks/page/PageContext';
import { SusPageProps } from '@sus-core/hooks/page/SusPageProps';

export default function RegisterPage({ location, pageContext }: SusPageProps) {
  const [accountCreated, setAccountCreated] = useState<boolean>(false);
  return (
    <PageProvider
      data={{
        pageType: 'Login',
        attributeMetaData: pageContext.attributeMetaData,
      }}>
      <Layout
        location={location}
        meta={{ title: 'Schrank und Stuhl - Konto anlegen' }}
        currentPageName="Konto anlegen">
        {accountCreated ? (
          <div className="mx-8 md:w-2/3 xl:w-1/3 md:mx-auto">
            <h4>Gleich kann's los gehen...</h4>
            <p>
              Bevor Sie sich in Ihrem Konto anmelden können, müssen Sie die
              E-Mail Adresse bestätigen.
            </p>
            <p>
              Wir haben Ihnen dazu eine E-Mail mit Bestätigungslink an die von
              Ihnen angegebene E-Mail-Adresse geschickt. Bitte überprüfen Sie
              Ihr Email Postfach.
            </p>
          </div>
        ) : (
          <RegisterForm onSuccess={() => setAccountCreated(true)} />
        )}
      </Layout>
    </PageProvider>
  );
}
